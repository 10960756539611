import React from 'react';
import ReactPaginate from 'react-paginate';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import Loader from 'react-loader-spinner';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";
import '../Landing/Groups.scss';
import AddButton from'../Landing/Add.png';
import {AccountConsumer} from"../AccountContext";
import JobForms from './job_forms'
import CloneJobForms from './cloneJob_forms';
import SortIcons from '../Landing/SortIcons';
import ReactHintFactory from 'react-hint';
import GenerateZip from "./generateZip";
import './Jobs.scss';
import AuthService from '../AuthService';
import FlashMessage from '../FlashMessage/FlashMessage';
import {ShowAllJobs} from './showAllJobs';
import { UncontrolledTooltip, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter, Button as BButton, Spinner } from 'reactstrap';
import WorkFlowTemplate from './workFlowTemplate';//TP-2329
import CheckListTemplate from './checkListTemplate';//TP-2884
import CheckListForms from './checklistForms';//TP-2479
import ScanCheckListForms from "./scanCheckListForms";//TP-5606
import { printConsole, getHostAbbreviationForSocket, getURL } from '../helpers';
import moment from 'moment';
import {diagonastics, diagnostics} from "../DiagonasticHelper";
import IncidentTable from './incidents';
import SimpleReactValidator from 'simple-react-validator';

const DetectRTC = require('detectrtc');
const ReactHint = ReactHintFactory(React);

const swapArrayElements = function(arr, indexA, indexB) {
  let temp = arr[indexA];
  arr[indexA] = arr[indexB];
  arr[indexB] = temp;
  return arr;
};

/*************************Function for getting the whole Assignee name for a JobId*************************/

const getAssigneeName = function(row){
    var name = row.first_name+" "+row.last_name;
    return name;
}

//TZ-30
const getExpertName = function(row){
    let name = row.expert_first_name+" "+row.expert_last_name;
    return name;
}
class Jobs extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            allJobsData:[],
            jobsData: [],
            jobsSearch: [],
            isLoading: false,
            pageCount: 0,
            pagination: {
                currentPage: 1,
                perPage: props.maxPerPage ? props.maxPerPage : 10 //TP-2883,
            },
            selectedJob: {},
            action: '',
            showJobForm: false,
            retCode: 400,
            errStrReturned: '',
            bSubmitted: false,
            bResponded: false,
            changeJobs: 0,
            sortColumn: {},
            searchStr: '',
            flashMsgText: "",
            showFlashMessage: false,
            flashLeft: "",
            flashTop: "",
            showJobsCheckbox:{
                value:false,
                label:"Show Finished Jobs",
                id:"show_my_jobs"
            },
            showTemplateCheckbox:{
                value: props.customer && (props.customer.enable_job_template !== "disabled" && props.isadmin || 
                (props.isexpert && ((props.enable_job_template && props.customer.enable_job_template === "enabled_on_demand") || props.customer.enable_job_template === "enabled_for_all" ))) ? true : false,
                label:"Show Only Templates",
                id:"show_template"
            },
            jobOptionDropdownOpen: false,
            webcamAvailable: false, micAvailable: false, tempGroupId: "",
            jobInProgress: [],  recipientEmail: "", usersInSession: props.usersInSession, //TP-1599
            userCallResponse: '', isIOS: false, woGroups: [],
            incidents: [],
            priority_options: [{value: "P0",label: "P0"}, {value: "P1",label: "P1"}, {value: "P2",label: "P2"}],//TZ-48
            severity_options: [{value: "low",label: "Low"}, {value: "medium",label: "Medium"}, {value: "high",label: "High"}],
            status_options: [{value: "created",label: "Created"}, {value: "in_progress",label: "In Progress"}, {value: "finished",label: "Finished"}],
            edittingIncident: {}
            //tasks_data: ""
        }
        this.authService = new AuthService();
        this.wrapperRef = React.createRef();//TP-2679
        this.isMount = false;
        this.timer = null;
		// initialize validator
        SimpleReactValidator.addLocale(props.language, require(`../../locales/${props.language}/messages.json`));
		this.validator = new SimpleReactValidator({
            locale: props.language,
            autoForceUpdate: this,
            element: message => <div className="job-form-validation-msg">{message.replace(/field|The|[0-9]/g, '').capitalize()}</div>,
		});
    }

    //TP-5766
    checkPlatform = () => {
        let ua = navigator.userAgent.toLowerCase(); 
        let chromeAgent = ua.indexOf("chrome") > -1; 
        let safariAgent = ua.indexOf("safari") > -1; 
        if ((chromeAgent) && (safariAgent)) safariAgent = false; 

        let isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (safariAgent)
        this.setState({isIOS})
    };

    componentDidMount = () => {
        const { isexpert } = this.props;
        //listener of socket signal
        this.authService.socket.on('job-signal', this.subscribeToJobSignal)
        this.authService.socket.on('respond-to-the-call', this.subscribeToRespondToTheCall);//TP-5766

        document.addEventListener("mousedown", this.handleClickOutside);//TP-2679
        //check camera
        if (isexpert === false) {
            DetectRTC.load(() => {
                this.setState({webcamAvailable: DetectRTC.hasWebcam && DetectRTC.hasMicrophone});
            });
        } else {
            DetectRTC.load(() => {
                this.setState({micAvailable: DetectRTC.hasMicrophone});
            });
        }
        //check platform for ios detection
        this.checkPlatform();

        //TP-5959, load the state with stored data, to be used after back to back calls, page refresh, login
        if (typeof(Storage) !== "undefined"){
            let storedWoGroups = sessionStorage.getItem("woGroups")
            if(storedWoGroups){
                let parsedWoGroups = JSON.parse(storedWoGroups);
                this.setState({woGroups: parsedWoGroups})
            }else{
                printConsole(`in componentdidmount no wo groups in session storage`)
            }
        }

        this.sendIAmReady();//TP-5998
        this.isMount = true;
    }

    componentWillReceiveProps(nextProps){
        //TP-3720, for expert enable_job_template is set to true late
        if(nextProps.enable_job_template !== this.props.enable_job_template && nextProps.enable_job_template){
            if(nextProps.customer && (nextProps.customer.enable_job_template !== "disabled" && nextProps.isadmin || 
            (nextProps.isexpert && ((nextProps.enable_job_template && nextProps.customer.enable_job_template === "enabled_on_demand") || nextProps.customer.enable_job_template === "enabled_for_all" )))){
                this.setState( prevState => ({ showTemplateCheckbox: { ...prevState.showTemplateCheckbox, value: true}}),() => {
                    this.fetchJobs();//refetch job
                })
            }
        }

        /**TP-2883 */
        if(nextProps.maxPerPage !== this.props.maxPerPage) {
            this.setState({ pagination: { currentPage: 1/* this.state.pagination.currentPage */, perPage: nextProps.maxPerPage }}, () => {
                const {perPage} = this.state.pagination;
                this.setState({
                    pageCount: Math.ceil(this.state.total / perPage)
                }, () => {
                    this.getFilteredJobs();
                })
            });
        }
    }

    componentWillUnmount = () => {
        this.authService.socket.removeListener('job-signal', this.subscribeToJobSignal)
        this.authService.socket.removeListener('respond-to-the-call', this.subscribeToRespondToTheCall);//TP-5766
        document.removeEventListener("mousedown", this.handleClickOutside);//TP-2679
        this.isMount = false;
        if (this.timer) clearTimeout(this.timer);
    }

    componentWillMount = () => {  
        this.fetchJobs(); 
    }

    getRndInteger = (min, max) => {
      return Math.floor(Math.random() * (max - min) ) + min;
    }

    sendIAmReady = () => {
        let { customer: {behaviour}, email } = this.props;
        if(behaviour === "pndsafety"){
            this.authService.socket.emit("i-am-ready", {type: behaviour, sender: email})
        }
    }
    
    subscribeToJobSignal = ({refresh_type, job_data, from, jobId}) => {
        //only for wo call
        if(refresh_type === "job_in_progress"){
            const parsedJobData = JSON.parse(job_data);
            const {work_in_progress, expert_id, group_id} = parsedJobData;
            const {userid} = this.props;
            if(userid === expert_id){
                printConsole(`subscribeToJobSignal in jobs ${JSON.stringify(job_data)}`)
                const {jobInProgress, woGroups} = this.state;
                const jobsNotToBeUpdated = jobInProgress.filter(j => j.jobId !== jobId)
                const groupsNotTobeAdded = woGroups.filter(g => g.recipientEmail !== from)//TP-5959 same signal may come twice
                const obj = {jobId, work_in_progress}
                const woGroupObj = {recipientEmail: from, woGroupId: group_id}
                this.setState((prevState) => ({jobInProgress: [...jobsNotToBeUpdated, obj], woGroups: [...groupsNotTobeAdded, woGroupObj],
                    tempGroupId: group_id
                }))
            }
            if(!work_in_progress){
                if (typeof(Storage) !== "undefined"){
                    let storedWoGroups = sessionStorage.getItem("woGroups");
                    //TP-5959 clear the object from array
                    if(storedWoGroups){
                        let parsedWoGroups = JSON.parse(storedWoGroups);
                        let filteredWoGroups = parsedWoGroups.filter(g => g.recipientEmail !== from)
                        if(filteredWoGroups && filteredWoGroups.length > 0){
                            sessionStorage.setItem("woGroups", JSON.stringify(filteredWoGroups));//TP-5856 remove the wo group id
                        }else{
                            printConsole(`no wo groups left to store`)
                            sessionStorage.removeItem("woGroups");//TP-5856 remove the wo group id
                        }
                    }else{
                        printConsole(`no storedWoGroups in session storage`)
                    }
                }else{
                    const message = "Sorry, your browser does not support Web Storage...";
                    printConsole(message);
                }
            }
        }else{
            if (this.state.jobsData.length > 0) {
                this.setState((prev) => ({
                    changeJobs: jobId
                }))
            }
        }
    }

    subscribeToRespondToTheCall = ({ action, groupName, groupId }) => {
        if (!this.isMount || this.props.activePage !== "jobs") return;
        printConsole("Job's tab listener for respond-to-the-call");
        let msg;
        const {isexpert} = this.props;
        const {tempGroupId: selectedGroupId} = this.state;
        // Clear the timer if there is already a timeout.
        if (this.timer && action !== "timeout") clearTimeout(this.timer);
        if (action === 'accepted') {
            this.props.updateCallDialogStatus(false);
            this.props.loadSelectedGroup(groupId, groupName);
            this.props.onRouteChange('session');
            return;
        } else if(action === 'declined') {
            this.props.updateCallDialogStatus(false);
            msg = i18nMark('User has declined your call. Please try later.');
            if (isexpert && selectedGroupId !== ''){
                this.clearSessionToken(selectedGroupId);
            }
            this.cancelWOCall({ action: 'callUser', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`) });
        } else if (action === 'in_session') {
            //TP-4228 -- Join the ongoing call if by same groupid else drop the call attempt only for Technician
            this.props.updateCallDialogStatus(false);
            msg = i18nMark('User is already on a call with another user, please try again later.');
        } else if (action === 'no_group_found') {
            this.props.updateCallDialogStatus(false);
            msg = i18nMark('You are not currently setup to call {recipient}');
        } else if (action === 'timeout') {
            this.props.updateCallDialogStatus(false);
            msg = i18nMark(`{recipient} did not pick up the call, please try again later.`);            
        }
        //TP-2420
        if (msg !== undefined) {
            this.setState({
                userCallResponse: msg
            });
        }
    }

    showHideJobOptions() {
        this.setState(prevState => ({
          jobOptionDropdownOpen: !prevState.jobOptionDropdownOpen
        }));
    }

    getFilteredJobs = () => {
        try{
            let { isadmin, customer, enable_job_template, isexpert } = this.props, filterArr;
            let { allJobsData, showJobsCheckbox: {value: show_finished_job}, showTemplateCheckbox: {value: show_template} } = this.state;
            if(!show_finished_job){
                filterArr = allJobsData.filter(job=>'finished'!==job.status);
                //TP-3720
                if(customer.enable_job_template !== "disabled" && isadmin || 
                (isexpert && ((enable_job_template && customer.enable_job_template === "enabled_on_demand") || customer.enable_job_template === "enabled_for_all" ))){
                    //show job button is toggled
                    if(show_template){
                        filterArr = filterArr.filter(job => job.job_type === 'work_instruction_template' || job.job_type === 'checklist_template' || job.job_type === 'scan_checklist_template');
                    }else{
                        filterArr = filterArr.filter(job => job.job_type !== 'work_instruction_template' && job.job_type !== 'checklist_template' && job.job_type !== 'scan_checklist_template');
                    }
                }
                this.setState({jobsData: filterArr},()=> this.searchJobs())
            }else{
                //TP-3720
                if(customer.enable_job_template !== "disabled" && isadmin || 
                (isexpert && ((enable_job_template && customer.enable_job_template === "enabled_on_demand") || customer.enable_job_template === "enabled_for_all" ))){
                    if(show_template){
                        filterArr = allJobsData.filter(job => job.job_type === 'work_instruction_template' || job.job_type === 'checklist_template' || job.job_type === 'scan_checklist_template');
                    }else{
                        filterArr = allJobsData.filter(job => job.job_type !== 'work_instruction_template' && job.job_type !== 'checklist_template' && job.job_type !== 'scan_checklist_template');
                    }
                }else{
                    filterArr = allJobsData //when finished job button is toggled on
                }
                this.setState({jobsData: filterArr},()=> this.searchJobs())
            }
            
            //check if any of the columns were already sorted
            // If yes then sort them back again
            const {sortColumn} = this.state;
            if (sortColumn.assignee_name === "asc") 
            this.sortJobs("assignee_name");
            else if (sortColumn.name === "asc")
            this.sortJobs("name");
            else if (sortColumn.status === "asc")
            this.sortJobs("status");
            else if (sortColumn.job_type === "asc")
            this.sortJobs("job_type");
        }catch(error){
            printConsole(`error in getFilteredJobs ${error}`)
        }
    }

    fetchJobs = () => {
        let { isadmin, accountId, customer, enable_job_template, isexpert } = this.props, jobs;
        const {searchStr, pagination: {perPage}, action, selectedJob, edittingIncident } = this.state;
        this.setState({ 
            isLoading: true
        });

        let fetchString = 'jobs/joblist/';
        fetchString += accountId + '/';
        fetchString += isadmin;
        //fetchString += `?perpage=${perPage}&currentpage=${currentPage}`;

        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){
                //TP-3640
                if(!(customer.enable_job_template !== "disabled" && isadmin || 
                (isexpert && ((enable_job_template && customer.enable_job_template === "enabled_on_demand") || customer.enable_job_template === "enabled_for_all" )))){
                    jobs = data.records.filter(job => job.job_type !== 'work_instruction_template' && job.job_type !== 'checklist_template' && job.job_type !== 'scan_checklist_template')
                }else{
                    jobs = data.records;
                }
                let allJobsData = jobs.filter(job=>"finished"!==job.status);
                let total = allJobsData.length;
                this.setState({
                   allJobsData:jobs, 
                   jobsData: this.state.showJobsCheckbox.value? jobs : allJobsData,//TP-1524
                   isLoading: false,
                   total: total,
                   pageCount: Math.ceil(total / perPage)
                },() => {
                    //if job is not searched then also
                    // if(searchStr){
                    //     this.searchJobs();
                    // }
                    // if(this.state.showJobsCheckbox.value){
                        this.getFilteredJobs();//TP-2581
                    // }
        
                });
                //TZ-154 only if fetch jobs is called while incident is open
                if(action === 'show_incidents'){
                    const jobWithIncidents = jobs.find(j => j.id === selectedJob.id);//find the job for which incident is open
                    if(jobWithIncidents){
                        let incidents = [...jobWithIncidents.incidents].map(i => ({...i, collapse: true})).sort((a, b) => a.id - b.id)//TZ-44 sort ascending
                        //change the incident which is editted
                        incidents = incidents.map(i => {
                            if(i.id === edittingIncident.id){
                                i.collapse = false;
                                i.field_changed = false;
                            }
                            return i
                        })
                        this.setState({
                            incidents
                        })
                    }
                }
            }
       })
       .catch(err => {
           this.setState({ isLoading: false });
           console.log(err.message)
       })
    }

    getJobsCurrentPage = (jobsData, pageNo) => {
      const { perPage } = this.state.pagination;
      let startIndex = Math.ceil(perPage*(pageNo-1));
      let endIndex = Math.ceil(perPage*(pageNo-1)) + perPage;
      let jobsCurrent = jobsData.slice(startIndex, endIndex);
      return jobsCurrent;
    }

    handlePaginationClick = (data) => {
        let selected = data.selected;
        const { pagination } = this.state;
        this.setState({ 
            pagination: {
                ...pagination,
                currentPage: selected + 1
            }
        }, () => {
            //this.fetchJobs();
        });
    };

    showDataLoader = () => {
        const { isLoading } = this.state;
        if (isLoading) {
            return ( <div className="loader">
                    <Loader
                        type="ThreeDots"
                        color="#23c97d"
                        height="70" 
                        width="70"
                    />
                </div> )
        } else {
            return '';
        }        
    }

    //TP-5766
    onClickVideoIcon = async ({row, action}) => {
        const micAvailable = await this.checkAudioAvailable();
        if (!micAvailable) {
            this.setState({ bResponded: true, errStrReturned: i18nMark('MicrophoneNotFoundError')})
            return false;
        }
        this.setState({selectedJob: row, action})
        this.getSessionTokenForSingleGroup({row})

    }

    //TP-5766
    checkAudioAvailable = () => {
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.getUserMedia({
                video: false,
                audio: true
            })
            .then((stream) => {
                stream.getTracks().forEach(track => track.stop());
                resolve(true);
            })
            .catch(err => {
                printConsole('cannot access microphone');
                printConsole(`Device detection Error :: ${err}`);
                resolve(false)
            });
        });
    }

    //TP-5766
    getSessionTokenForSingleGroup = ({row}) => {
        let {woGroups} = this.state, groupId, selectedGroup;
        let recipientEmail = row.email;//TP-5959, when coming back after first wo call or page refresh, this will get overwritten everytime incase of multi-tech scenario
        [selectedGroup] = woGroups.filter(g => g.recipientEmail === recipientEmail);
        if(selectedGroup){
            groupId = selectedGroup.woGroupId
        }
        if (typeof(Storage) !== "undefined"){
            if(!groupId){
                printConsole(`no wo group id in state`)
                let storedWoGroups = sessionStorage.getItem("woGroups")
                if(storedWoGroups){
                    let parsedWoGroups = JSON.parse(storedWoGroups);
                    [selectedGroup] = parsedWoGroups.filter(g => g.recipientEmail === recipientEmail)//TP-5959 pick it from storage
                    if(selectedGroup){
                        groupId = selectedGroup.woGroupId
                    }
                    if(!groupId){
                        printConsole(`no wo group id in session storage`)
                    }
                }else{
                    printConsole(`no wo groups in session storage`)
                }
            }else{
                sessionStorage.setItem("woGroups", JSON.stringify(woGroups));//TP-5856 set the wo group id
                printConsole(`wo group id set in session storage`)
            }
        }else{
            const message = "Sorry, your browser does not support Web Storage...";
            printConsole(message);
        }
        let fetchString = 'sessiondataH364?groupID=';
        fetchString += groupId;
        fetchString += '&loggedInUserId=';
        fetchString += this.props.userid;
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => {
            return response.json();
        })
        .then((data) => {
            if (this.props.log_level >= 3) {
                diagnostics(this.props.authService,{
                    action: `sessionData`,
                    sequence: 3,
                    next_step: '',
                    data: data,
                    error: 'none'
                }) 
            }
            printConsole(data);
            this.props.loadSessionInfo(data);
            this.props.updateCallDialogStatus(true); //TP-5547
            this.timer = setTimeout(() => this.cancelWOCall({ action: 'timeoutCall', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`) }), window._env_.REACT_APP_API_CALL_TIME_OUT);
            this.props.setGroupUsersList(groupId);//TP-1735
            // TP-1572 -- Setting the isBusy flag for the recipient user to whom the new connect-the-call signal is triggered
            // TP-5959 recipientEmail to be used in cancel call
            this.setState(prevState => ({usersInSession: [...prevState.usersInSession, recipientEmail]}),() =>{
                this.props.setUsersInSession(this.state.usersInSession);//TP-1599
            });
            this.authService.socket.emit('connect-the-call', {
                recipientEmail, 
                callerName: this.props.name, 
                isCancelled: false,
                group_id: groupId,
                source: "user" 
            });
            if (this.props.log_level >= 3) {
                diagonastics(this.props.authService,{
                    action: `connect-the-call is emitted`,
                    sequence: 16,
                    next_step: '',
                    data: `{ recipientEmail: ${recipientEmail}, callerName: ${this.props.name}, isCancelled: false, group_id: ${groupId} }`,
                    error: 'none'
                })
            }
        
            this.props.isUserCall(true); //TP-5036
            this.props.setCalleeList(null);//TP-2381                
            this.setState({ userCallResponse: '' })
        })
        .catch((error) => {
            printConsole(error)
            if (this.props.log_level >= 3) {
                diagonastics(this.authService,{
                    action: `sessiondataH364 has returned error`,
                    sequence: 1,
                    next_step: '',
                    data: `groupID: ${groupId}, loggedInUserId: ${this.props.userid}`,
                    error: `error code: ${error.status}`
                })      
            }      
            // output error in sessiondata
            if(error.status === 444) {
                this.setState({action: 'message', errStrReturned: i18nMark('Establishing the live call failed due to a server error (444). Please contact Telepresenz support if this problem persists.')});
            } else {
                this.setState({action: 'message', errStrReturned: i18nMark('The call could not proceed due to some issues.')});
            }
        })
    }

    //TP-5766
    cancelWOCall = ({ action = 'view', msg = ''}) => {
        const {tempGroupId: groupId, recipientEmail} = this.state;
        if (this.props.log_level >= 3) {
            diagonastics(this.props.authService,{
                action: `cancelling the video call`,
                sequence: 3,
                next_step: '',
                data: msg,
                error: 'none'
            })
        }
        if (this.timer) clearTimeout(this.timer);
        this.setState({
            action,
            isLoading: false,
            userCallResponse: msg,
        }, () => {
            this.props.updateCallDialogStatus(false); //TP-1462
        })
        //TP-1572 -- Resetting the Call Icon for the user whose call has timed out/cancelled
        let {usersInSession} = this.state;
        usersInSession = usersInSession.filter(e => e !== recipientEmail);
        this.setState({usersInSession});
        this.props.setUsersInSession(usersInSession);
        this.props.authService.socket.emit('connect-the-call', { recipientEmail, isCancelled: true, callerName: this.props.name, source: "user" });
        this.props.isUserCall(true); //TP-5036
        this.props.setCalleeList(null);//TP-2381
        this.props.setCalleeList(null);//TP-2381
        this.clearSessionToken(groupId)
        // of a Group call if the call is timeout and no one accepts/declines the call
        if (action === "callUser") {
            this.authService.socket.emit('send-missed-call-notification', { 
                emails: [recipientEmail]
            });
        }
    }

    // TP-1550 -- Close the timeout call Dialog box
    closeTimeoutDialog = () => {
        this.setState({
            action: 'view',
            isLoading: false,
            userCallResponse: ''            
        }, () => {
            this.props.updateCallDialogStatus(false); 
        })
    }

    clearSessionToken = (groupId) => {
        this.authService.fetch('updateGroupToken', {
            method: 'post',
            body: JSON.stringify({
                groupId
            })
        })
        .then(response => response.json())
        .then(data => {
            printConsole('token cleared from jobs tab')
        })
        .catch(err => printConsole(err.message))
    }

    /*****************Search & Sort Jobs Functionality Begins***************/

    searchJobs = () => {
        try{
            const criteriaArr = ['name','assignee_name', 'status', 'job_type', 'expert_name', 'location_name'];//TZ-31
            let input, filter, i, total;
            let { isadmin, customer, enable_job_template, isexpert } = this.props;
            let { allJobsData, jobsData, jobsSearch, showJobsCheckbox: {value: show_finished_job}, showTemplateCheckbox: {value: show_template},
            pagination: {perPage}} = this.state;
            input = document.getElementById("jobSearchBox");
            filter = input ? input.value.toUpperCase() : '';//since the ui has changed from modal to same page ui the input is not found after add,edit

            //TP-3720 when template is on by default show only templates
            if((customer.enable_job_template !== "disabled" && isadmin || 
            (isexpert && ((enable_job_template && customer.enable_job_template === "enabled_on_demand") || customer.enable_job_template === "enabled_for_all" )))){
                if(show_template){
                    allJobsData = allJobsData.filter(job => job.job_type === 'work_instruction_template' || job.job_type === 'checklist_template' || job.job_type === 'scan_checklist_template');
                }else{
                    allJobsData = allJobsData.filter(job => job.job_type !== 'work_instruction_template' && job.job_type !== 'checklist_template' && job.job_type !== 'scan_checklist_template');
                }
            }
    
            if(!show_finished_job && "FINISHED".indexOf(filter) > 0){
                allJobsData = allJobsData.filter(job=>"finished"!==job.status);
            }else if(!show_finished_job && "FINISHED".indexOf(filter) === -1){
                allJobsData = allJobsData.filter(job=>"finished"!==job.status);
            }else if(!filter){
                allJobsData = jobsData;
            }
            if (filter === "UNASSIGNED"){
                //TZ-31
                jobsSearch = allJobsData.filter((jobs)=> {
                    // return the records with no tech name or expert name
                    return ((jobs.first_name === null && jobs.last_name === null)
                            || 
                            (jobs.expert_first_name === null && jobs.expert_last_name === null));
                });
            } else {
                for (i = 0; i < criteriaArr.length; i++) {
                    jobsSearch = allJobsData.filter((jobs)=> {
                        if (criteriaArr[i] === 'assignee_name') {
                            let name = jobs.first_name+" "+jobs.last_name;
                            return name.toUpperCase().indexOf(filter) > -1;
                        } else if (criteriaArr[i] === 'expert_name') {
                            let name = jobs.expert_first_name + " " + jobs.expert_last_name;//TZ-30
                            return name.toUpperCase().indexOf(filter) > -1;
                        } else {
                            return jobs[criteriaArr[i]].toUpperCase().indexOf(filter) > -1;
                        }
                    });
    
                    if (jobsSearch.length > 0) break; 
                }
            }
            // reset the page count after search
            if(JSON.stringify(allJobsData)==JSON.stringify(jobsSearch)){
                // jobsSearch = [];//TP-2266
                total = allJobsData.length;
            }else{
                total = jobsSearch.length;
            }
            this.setState({
                jobsSearch: jobsSearch,
                pageCount: Math.ceil(total / perPage),
                searchStr: filter
            }, () => {
                // if(this.state.pageCount !== 0 && this.state.pageCount < currentPage){
                //      this.handlePaginationClick({selected: currentPage - 2})
                //     //this.handlePaginationClick({selected: 1})
                // }
                if(this.state.pageCount && (!this.state.showJobsCheckbox.value || filter)){
                    this.handlePaginationClick({selected: 0})
                }
                //console.log("Serach Array: ********************");
                //console.log(this.state.jobsSearch);
            })
        }catch(error){
            printConsole(`error in search jobs- ${error}`)
        }
    }

    
    sortJobs = (column) => {
        let x,y,shouldSwitch, i, jobsList, searched;
        const { jobsData, jobsSearch } = this.state;
        if (jobsSearch.length > 0 ) {
            jobsList = jobsSearch.slice();
            searched = true;
        } else if (jobsData.length > 0) {
            jobsList = jobsData.slice();
            searched = false;
        }
        
        let switching = true;
        let switchcount = 0;
        
        //Set the sorting direction to ascending:
        let dir = 'asc';
        while (switching) {
            switching = false;
            for (i = 0; i < (jobsList.length - 1); i++) {
                //start by saying there should be no switching:
                shouldSwitch = false;
                /*Get the two elements you want to compare,
                one from current row and one from the next:*/
                 switch (column) {
                    case 'assignee_name': 
                        x = getAssigneeName(jobsList[i]);
                        y = getAssigneeName(jobsList[i+1]);
                    break;
                    //TZ-30
                    case 'expert_name': 
                        x = getExpertName(jobsList[i]);
                        y = getExpertName(jobsList[i+1]);
                    break;
                    default:
                        x = jobsList[i][column];
                        y = jobsList[i + 1][column];
                }
                
                /*check if the two rows should switch place,
                based on the direction, asc or desc:*/
                if (dir === 'asc') {
                    if (x.toLowerCase() > y.toLowerCase()) {
                      //if so, mark as a switch and break the loop:
                      shouldSwitch= true;
                      break;
                    }
                } else if (dir === 'desc') {
                    if (x.toLowerCase() < y.toLowerCase()) {
                      //if so, mark as a switch and break the loop:
                      shouldSwitch = true;
                      break;
                    }
                }
            }

            if (shouldSwitch) {
              /*If a switch has been marked, make the switch
              and mark that a switch has been done:*/
              jobsList = swapArrayElements(jobsList, i + 1, i);
              switching = true;
              //Each time a switch is done, increase this count by 1:
              switchcount ++;      
            } else {
              /*If no switching has been done AND the direction is "asc",
              set the direction to "desc" and run the while loop again.*/
              if (switchcount === 0 && dir === 'asc') {
                dir = "desc";
                switching = true;
              }
            }
        }

        const sortColumn = {
            [column]: dir
        }

        if (searched) {
            this.setState({
                jobsSearch: jobsList,
                sortColumn: sortColumn
            })
        } else {
            this.setState({
                jobsData: jobsList,
                sortColumn: sortColumn
            })
        }
    }

    /*****************Search & Sort Jobs Functionality Ends***************/

    changeJobView = ({action, showJobForm, selectedJob}) => {
        this.setState({
            action,
            showJobForm,
            selectedJob
        }, () => {
            if (this.state.showJobForm === true) {
                this.setState({ jobOptionDropdownOpen: false });
            }
            //console.log(this.state.selectedJob);
        })
    }

    // TP-3161- extracting the ternary with seperate function
    changeJobViewForEdit = (row) => {
        if(row.job_type === 'checklist'){
            this.changeJobView({action: 'edit_checklist', showJobForm:true, selectedJob: row}) 
        }else if(row.job_type === 'checklist_template'){
            this.changeJobView({action: 'edit_checklist_template', showJobForm:true, selectedJob: row})
        }else if(row.job_type === 'evidence'){
            this.changeJobView({action: 'edit_evidence_job', showJobForm:true, selectedJob: row})
        }else if(row.job_type === 'work_instruction_template'){
            this.changeJobView({action: 'edit_evidence_template', showJobForm:true, selectedJob: row})
        }else if(row.job_type === 'scan_checklist'){
            this.changeJobView({action: 'edit_scan_checklist', showJobForm:true, selectedJob: row})//TP-5606
        }else if(row.job_type === 'scan_checklist_template'){
            this.changeJobView({action: 'edit_scan_checklist_template', showJobForm:true, selectedJob: row})//TP-5606
        }
    }

    // TP-3566- extracting the ternary with seperate function
    changeJobViewForClone = (row) => {
        if(row.job_type === 'checklist'){
            this.changeJobView({action: 'clone_checklist_job', showJobForm:true, selectedJob: row}) 
        }else if(row.job_type === 'evidence'){
            this.changeJobView({action: 'clone_evidence_job', showJobForm:true, selectedJob: row})
        }else if(row.job_type === 'work_instruction_template'){
            this.changeJobView({action: 'clone_evidence_template', showJobForm:true, selectedJob: row})
        }else if(row.job_type === 'checklist_template'){
            this.changeJobView({action: 'clone_checklist_template', showJobForm:true, selectedJob: row})
        }
    }

    //TP-6629
    showIncidents = (row) => {
        this.setState(() => ({
            incidents: [...row.incidents].map(i => ({...i, collapse: true})).sort((a, b) => a.id - b.id)//TZ-44 sort ascending
        }),() => {
            this.selectIncidentDropdownValue()//TZ-37
            this.changeJobView({action: 'show_incidents', showJobForm:true, selectedJob: row})
        })
    }

    //TP-6629
    collapseIncidents = (idx) => {
        let incidents = [...this.state.incidents]
        incidents = incidents.map((incident, i) => {
            if(idx === i){
                return {...incident, collapse: !incident.collapse}
            }else{
                return {...incident, collapse: true}
            }
        }) 
        this.setState({ 
            incidents,
        })
    }

    // TZ-37
    onChangeIncidentForm = (fieldName) => ({ target }) => {
        let incidents = [...this.state.incidents]
        incidents[target.dataset.id][fieldName] = target.value.trimStart().replace(/(<([^>]+)>)/ig,"");
        incidents[target.dataset.id]["field_changed"] = true//TZ-46
        this.setState({ incidents })
    }

    // TZ-37
    onChangeIncidentDropdown = ({idx, fieldName, options, value_option}) => (selected_option) => {
        try{
            let incidents = [...this.state.incidents];//all incident
            const all_options = [...this.state[options]];//all options from which selected option is to be selected
            const selected = all_options.find(p => p.value === selected_option.value)//select it
            incidents[idx][fieldName] = selected_option.value;// set the value
            incidents[idx][value_option] = {
                value: selected.value,
                label: selected.label
            };//set the selected option
            incidents[idx]["field_changed"] = true//TZ-46
            this.setState({ incidents })
        }catch(error){
            printConsole(`error in onChangeIncidentDropdown, ${error}`)
        }
    }

    // TZ-37
    selectIncidentDropdownValue = () => {
        try{
            let incidents = [...this.state.incidents];
            const {priority_options, severity_options, status_options} = this.state;// all options
            //loop incident and set the values
            const new_incidents = incidents.map((i) => {
                const selected_priority = priority_options.find(p => p.value === i.priority)
                if(selected_priority){
                    i["selected_priority"] = {
                        value: selected_priority.value,
                        label: selected_priority.label
                    };
                }
    
                const selected_severity = severity_options.find(p => p.value === i.severity)
                if(selected_severity){
                    i["selected_severity"] = {
                        value: selected_severity.value,
                        label: selected_severity.label
                    };
                }
    
                const selected_status = status_options.find(p => p.value === i.status)
                if(selected_status){
                    i["selected_status"] = {
                        value: selected_status.value,
                        label: selected_status.label
                    };
                }
                return i
            })
    
            this.setState({ incidents : new_incidents})

        }catch(error){
            printConsole(`error in selectIncidentDropdownValue, ${error}`)
        }
    }

    // TZ-37
    onSubmitIncident = ({e, index}) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
			this.validator.showMessages();
            const fields = this.validator.fields;
            //TZ-54 find invalid incident fields and extract the index number
            let invalidFields = [], matches= [];
            for(let key in fields) {
                if(fields[key] === false && /\d/.test(key)){
                    invalidFields.push(key)
                }
            }
            // loop through the invalid fields to find out the field name with number in it
            invalidFields.map((f) => {
                let matchArr = f.match(/\d+/g);
                matches.push(matchArr[0]); 
            })
    
            matches = [...new Set(matches)];//remove the duplicate
            this.setState({
                errStrReturned: ""//TZ-49
            })
            // if the index is there in the match array
            if(matches.includes(index.toString())){
                return;
            }
		}

        const incidents = [...this.state.incidents]
        const incident = {...incidents[index]};//TZ-111 build the object, cannot use the same because we are setting comment to null
		this.setState({
            bSubmitted: true,
            errStrReturned: 'Submitting incident details'
        });
        this.props.authService.fetch('jobs/editIncident', {
            method: 'put',
            body: JSON.stringify(incident)
        })
        .then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
				return response.json();
			} else {
				throw new Error(response.body);
            }
        })
        .then(([id]) => {
			if(id){
				this.setState({
                    bSubmitted: false,
                    edittingIncident: incident,//to be used in fetch job
                    errStrReturned: 'Incident updated Successfully'
                });
                this.fetchJobs();//TZ-154 reload the jobs to get updated incidents
			}
        })
        .catch((error) => {
            this.setState({
                bSubmitted: false,
                retCode: error.status,
                errStrReturned: i18nMark('Failed to update incident.')
            });
        });

    }

    closeDeleteJobDialog = (e) => {
        this.setState({
            action: ''
        });
    };

    titleize = (str) => {
        let string_array = str.split('_');
        string_array = string_array.map(function(str) {
           return str.capitalize(); 
        });
        
        return string_array.join(' ');
    }   

    /* updateJobsDetails = (jobDetails) => {
        this.setState({             
            tasks_data: JSON.stringify(jobDetails.subTasks)
        })
    } */

    assignToTechnician = ({e, selectedJob}) => {
        e.preventDefault(); 
        //console.log(selectedJob);
        //console.log(this.state.past_technician_id);
        //console.log(selectedJob.status);
        //console.log(selectedJob.technician_id); 
        const job_data = {
            id: selectedJob.id,
            name: selectedJob.name,
            description: selectedJob.description,
            status: selectedJob.status,
            start_date: selectedJob.start_date,
            end_date: selectedJob.end_date,
            job_type: selectedJob.job_type//TP-3299
        } 
        let json = {  
            type: "ASSIGNED_JOB_REFRESH",          
            to: selectedJob.technician_id,
            from: this.props.email,
            job_data: JSON.stringify(job_data),            
            refresh_type: "new_job"
        };

        //console.log(json);
        
        //return;
        this.setState({
            bSubmitted: true,
            errStrReturned: i18nMark({ id: `Sending job to technician`, values: {role: this.props.customerRoles.technician}})
        });
        if(selectedJob){
            const url = ['checklist', "scan_checklist"].includes(selectedJob.job_type) ? 'assignCheckListJobToTechnician' : 'assignToTechnician'
            this.props.authService.fetch(`jobs/${url}/${selectedJob.id}`, {
                method: 'get'
            })
            .then(response => {
                this.setState({retCode: response.status});
                if(response.status === 200){
                    this.setState({
                        // bResponded: true,//MB2-923
                        bSubmitted: false,
                        // errStrReturned: i18nMark('Job assigned to technician'),//MB2-923
                    });
                    this.fetchJobs();//reload the jobs
                    //TP-2958
                    if (selectedJob.status === "created" && json.to){
                        this.authService.socket.emit('job-signal', json);//TP-1229,signal needs to be sent after api call success
                    }      
                    return response.json();
                } else {
                    throw new Error(response.body);
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    bResponded: true,
                    bSubmitted: false,
                    errStrReturned: i18nMark('Failed to process request'),
                    retCode: error.status
                });
        })
        }
    }

    //Flash message specific methods
    updateShowMessage = (val) => {
        if (val === false)
            this.setState({ flashMsgText: "", showFlashMessage: val, flashLeft: "", flashTop: "" });
    }

    onDeleteJob = (e) => {
        if (e) e.preventDefault();
        const { selectedJob, jobsSearch, jobsData, allJobsData, pagination: {currentPage, perPage}  } = this.state;
        let url, jobsList = jobsSearch.length > 0 ? jobsSearch : jobsData
        const {customer} = this.props
        //T32-169,TP-2359,2628
        if (selectedJob && (selectedJob.status==='created' || selectedJob.status==='draft')){
            url = ['checklist', 'checklist_template', "scan_checklist", "scan_checklist_template"].includes(selectedJob.job_type) ? 'deleteCheckListJob' : 'deleteJob'
        }else{
            //TP-2954, 6887, TZ-840
            if((selectedJob.technician_id || selectedJob.asset_id )  && customer.behaviour !== "honeywell"){
                url = 'markJobAsDeleted'
            }else{
                url = ['checklist', 'checklist_template', "scan_checklist", "scan_checklist_template"].includes(selectedJob.job_type) ? 'deleteCheckListJob' : 'deleteJob'
            }
        }
        //const message = i18nMark('Deleting the Job');
        const message = "Selected Job Deleted Successfully";
        /* this.setState({
            action: 'message',
            bSubmitted: true,
            errStrReturned: i18nMark('Deleting the Job')
        }); */

        const job_data = {
            id: selectedJob.id, name: selectedJob.name, description: selectedJob.description,
            start_date: selectedJob.start_date, end_date: selectedJob.end_date,
            status: selectedJob.status, reference_id: selectedJob.reference_id,
            job_type: selectedJob.job_type
        }
        let json = {  
            type: "ASSIGNED_JOB_REFRESH",          
            to: selectedJob.technician_id,
            from: this.props.email,
            job_data: JSON.stringify(job_data),            
            refresh_type: "delete_job"
        };

        this.setState({ 
            bSubmitted: true,
            action: '',
        });
        if(selectedJob){
            this.props.authService.fetch(`jobs/${url}/${selectedJob.id}`, {
                method: 'delete'
            })
            .then(response => {
                this.setState({retCode: response.status});
                if(response.status === 200){
                    jobsList = jobsList.filter(e => e.id !== selectedJob.id);
                    let mAllJobsData = allJobsData.filter(el => el.id !== selectedJob.id);
                    let mJobsData = jobsData.filter(ele => ele.id !== selectedJob.id);
                    let total = jobsList.length;
                    let pageCount = Math.ceil(total / perPage);
                    if (jobsSearch.length > 0){
                        this.setState({ 
                            // bResponded: true,//MB2-923
                            bSubmitted: false,                            
                            // errStrReturned: i18nMark('Job Deleted'),//MB2-923
                            jobsSearch: jobsList,
                            jobsData: mJobsData,//keep the updated state ready
                            allJobsData:mAllJobsData,
                            pageCount
                        },() => {
                            if(pageCount !== 0 && pageCount < currentPage){
                                this.handlePaginationClick({selected: currentPage - 2})
                            }
                        });
                    }else{
                        this.setState({
                            // bResponded: true,//MB2-923
                            bSubmitted: false,
                            //errStrReturned: i18nMark('Job Deleted'),//MB2-923
                            jobsData: mJobsData,
                            allJobsData:mAllJobsData,
                            pageCount
                        },() => {
                            if(pageCount !== 0 && pageCount < currentPage){
                                this.handlePaginationClick({selected: currentPage - 2})
                            }
                        });
                    }
                    //TP-2958, 5719
                    if(json.to && selectedJob.status !== "created"){
                        this.authService.socket.emit('job-signal', json); 
                    }
                    return response.json();
                } else {
                    throw new Error(response.body);
                }
            })
            .catch((error) => {
                this.setState({
                    bResponded: true,
                    bSubmitted: false,
                    errStrReturned: i18nMark('Failed to process request'),
                    retCode: error.status
                });
        })
        }
    }

    onClickDownloadReport = (index) => {
        let selectedJob, newJobs, searched;
        let {language} = this.props;//TP-3993
        if (this.state.jobsSearch.length > 0) {
            selectedJob = this.state.jobsSearch[index];
            newJobs = this.state.jobsSearch.slice();
            searched = true;
        } else {
            selectedJob = this.state.jobsData[index];
            newJobs = this.state.jobsData.slice();
            searched = false;
        }
        
        this.setState({ isLoading: true });
        if(selectedJob){
            const d = new Date();
            let timeZone = (Intl.DateTimeFormat().resolvedOptions().timeZone);//local Timezone
            if(timeZone && timeZone.includes("/")){
                timeZone = timeZone.replace(/\//g, '-')
            }
            const url = ['checklist', "scan_checklist"].includes(selectedJob.job_type) ? 'downloadCheckListJobReport' : 'downloadJobReport'
            this.props.authService.fetch(`jobs/${url}/${selectedJob.id}/${language}/${timeZone}`, {
                method: 'get'
            })
            .then(response => response.json())
            .then(async data => {
                // await JobReport({data, selectedJob, customer: this.props.customer});//T32-10 const {vfs} = vfsFonts.pdfMake; pdfMake.vfs = vfs; pdfMake.createPdf(data).download(`${selectedJob.name}-Report.pdf`);
                const link = document.createElement('a');
                link.href = 'data:application/pdf;base64,' + data.encodedReportdata; link.download = `${selectedJob.name}-Report.pdf`;
                link.click(); link.remove();
              
                if (selectedJob.is_downloaded === true) {
                    this.setState({
                        isLoading: false
                    })
                } else {
                    selectedJob.is_downloaded = true;
                    newJobs[index] = selectedJob;
                    if (searched) {
                        this.setState({
                            ...this.state,
                            jobsSearch : newJobs,
                            isLoading: false
                        }) 
                    } else {
                        this.setState({
                            ...this.state,
                            jobsData : newJobs,
                            isLoading: false
                        }) 
                    }
                }
                 
            })
            .catch((error) => {
                this.setState({
                    isLoading:false,
                    bResponded: true,
                    bSubmitted: false,
                    errStrReturned: i18nMark('Failed to process request'),
                    retCode: error.status
                });
            })
        }
    }

    closeLoader = (value) => {
        this.setState({
            isLoading: value
        }, () => {
            if (value === false) {
                this.onDeleteJob();
            }
        })
    }

    // Download Job Report locally into a Zip File
    onClickZipFile = (e) => {
        e.preventDefault();
        let {selectedJob} = this.state;
        let {language} = this.props;//TP-3993
        //console.log("Here ------->", selectedJob);
        /* let newJobs, searched;
        if (this.state.jobsSearch.length > 0) {
            //selectedJob = this.state.jobsSearch[index];
            newJobs = this.state.jobsSearch.slice();
            searched = true;
        } else {
            //selectedJob = this.state.jobsData[index];
            newJobs = this.state.jobsData.slice();
            searched = false;
        } */
        this.setState({ isLoading: true });
        if(selectedJob){
            const url = ['checklist', "scan_checklist"].includes(selectedJob.job_type) ? 'getCheckListJobReport' : 'getJobReport'
            this.props.authService.fetch(`jobs/${url}/${selectedJob.id}`, {
                method: 'get'
            })
            .then(response => response.json())
            .then(async data => {
                GenerateZip({data, selectedJob, customer: this.props.customer, callBackFunction: this.closeLoader, language});
                //this.onDeleteJob(e);
                /* if (selectedJob.is_downloaded === true) {
                    this.setState({
                        //isLoading: false
                    })
                } else {
                    selectedJob.is_downloaded = true;
                    newJobs[index] = selectedJob;
                    if (searched) {
                        this.setState({
                            ...this.state,
                            jobsSearch : newJobs,
                            //isLoading: false
                        }) 
                    } else {
                        this.setState({
                            ...this.state,
                            jobsData : newJobs,
                            //isLoading: false
                        }) 
                    }
                } */
            })
            .catch((error) => {
                this.setState({
                    isLoading:false,
                    bResponded: true,
                    bSubmitted: false,
                    errStrReturned: i18nMark('Failed to process request'),
                    retCode: error.status
                });
            })
        }
    }

    showAllJobs = () => {
        this.setState(({
            showJobsCheckbox:{
                ...this.state.showJobsCheckbox,
                value:!this.state.showJobsCheckbox.value,
                
            },
        }),()=>this.getFilteredJobs())
    }
    
    showAllTemplate = () => {
        this.setState((prevState) => ({
            showTemplateCheckbox:{
                ...prevState.showTemplateCheckbox,
                value:!prevState.showTemplateCheckbox.value,
            },
            //TP-5743
            // showJobsCheckbox:{
            //     ...prevState.showJobsCheckbox,
            //     value:false,
            // },
        }),()=>this.getFilteredJobs())
    }

    //TP-2679
    handleClickOutside = (event) => {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({jobOptionDropdownOpen: false})
        }
    }

    //TZ-176
    getRandomPass = (e) => {
        e.preventDefault()
        const hostAbbr = getHostAbbreviationForSocket();//TZ-505
        this.props.authService.fetch('user/getSupportPass', {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data){       
                window.open(`${window._env_.REACT_APP_SUPPORT_URL}/#/dashboards/honeywell/?data=${data}&referrer=${hostAbbr}`, '_blank');//TZ-204
            }
        })
        .catch(err => {
            printConsole(err.message)
        })
    }
    
    jobList = () =>{
        const { changeJobs, jobsSearch, jobsData, jobOptionDropdownOpen ,searchStr, pagination: { currentPage, perPage }, showTemplateCheckbox: {value: show_template}, jobInProgress } = this.state;
        const { isadmin, isexpert, customer, enable_asset, enable_job_template, isSmallFormFactor } = this.props; //TP-2721
        const is_template_enabled = customer.enable_job_template !== "disabled" && isadmin || 
                            (isexpert && ((enable_job_template && customer.enable_job_template === "enabled_on_demand") || customer.enable_job_template === "enabled_for_all" ))
        let jobsList = [];
        if (jobsSearch.length > 0 || searchStr !== '') {
            jobsList = this.getJobsCurrentPage(jobsSearch, currentPage)
        } else if (jobsData.length > 0) {
            jobsList = this.getJobsCurrentPage(jobsData, currentPage)
        }               
        return(<>
                <ReactHint autoPosition events />
                <div className="row">
                    {/* TP-6830 */}
                    {isexpert && !show_template && customer.behaviour !== "honeywell"? 
                        <div className='col-3'>
                            <I18n>
                                {({ i18n }) =>
                                    <>
                                        { /**TP-2738 */!customer.enable_checklist && customer.behaviour !== "pilot" ?
                                            <>
                                                <input type="image" id="addJob" src={AddButton} className="btn-green-add" alt={i18n._(t`Add Job`)} onClick={()=>{this.changeJobView({action:'add_evidence_job', showJobForm:true})} } />
                                                { isSmallFormFactor === false ?
                                                    <UncontrolledTooltip placement="right" target="addJob">
                                                        <Trans>Click to add a new job</Trans>
                                                    </UncontrolledTooltip> 
                                                    :                                           
                                                    ''
                                                }
                                            </>
                                            :
                                            <>
                                                {
                                                    customer.behaviour === "pndsafety"?
                                                        null
                                                    :
                                                    <>
                                                        <input type="image" id="addJob" src={AddButton} className="btn-green-add" alt={i18n._(t`Add Job`)} onClick={() => this.showHideJobOptions() } />
                                                        { isSmallFormFactor === false ?
                                                            <UncontrolledTooltip placement="right" target="addJob">
                                                                <Trans>Click to add a new job</Trans>
                                                            </UncontrolledTooltip>
                                                            :
                                                            ''
                                                        }
                                                        <div ref={this.wrapperRef} className="dropdown-content pointer" style={{display:jobOptionDropdownOpen?"block":"none"}} onMouseLeave={() => this.setState({jobOptionDropdownOpen: false})}>
                                                            <a onClick={()=>{this.changeJobView({action:'add_evidence_job', showJobForm:true})}}>
                                                                <Trans>Add Job</Trans> - <Trans>Evidence</Trans>
                                                            </a>
                                                            { /**TP-2738 */ customer.enable_checklist ?
                                                                <a onClick={()=>{this.changeJobView({action:'add_checklist', showJobForm:true})}}>
                                                                    <Trans>Add Job</Trans> - <Trans>Checklist</Trans>
                                                                </a>
                                                                :
                                                                null
                                                            }
                                                            {/**TP-3161 */}
                                                            {/* {customer.enable_job_template && (isadmin || enable_job_template) ?
                                                                <>
                                                                    <a onClick={()=>{this.changeJobView({action:'add_evidence_template', showJobForm:true})}}>
                                                                        <Trans>Add Template</Trans> - <Trans>Evidence</Trans>
                                                                    </a>
                                                                    {customer.enable_checklist ?
                                                                        <a onClick={()=>{this.changeJobView({action:'add_checklist_template', showJobForm:true})}}>
                                                                            <Trans>Add Template</Trans> - <Trans>Checklist</Trans>
                                                                        </a>
                                                                        :
                                                                        null
                                                                    }
                                                                </>
                                                                :
                                                                null
                                                            } */}
                                                            {
                                                                customer.behaviour === 'pilot' ? 
                                                                    <>
                                                                        <a onClick={()=>{this.changeJobView({action:'upload_workflow_template'})}}>
                                                                            <Trans>Import</Trans> <Trans>Evidence</Trans> (<Trans>CSV</Trans>)
                                                                        </a>
                                                                        <a onClick={()=>{this.changeJobView({action:'upload_checklist_template'})}}>
                                                                            <Trans>Import</Trans> <Trans>Checklist</Trans> (<Trans>CSV</Trans>)
                                                                        </a>
                                                                    </>
                                                                    : null
                                                            }
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </I18n>
                            {/* TZ-580 TZ-59 */}
                            {
                                customer.enable_inspection || customer.enable_incident?
                                    <>
                                        <a href="#" target="_blank" rel="noreferrer" id="power-bi-3" className="white" onClick={(e) => this.getRandomPass(e)} style={{position:"relative", bottom:"8px"}}>
                                            <i class="fas fa-chart-bar fa-2x"></i>
                                        </a>
                                        {isSmallFormFactor === false?
                                            <UncontrolledTooltip placement="down" target="power-bi-3">
                                                Dashboard
                                            </UncontrolledTooltip>
                                            :                                           
                                            null
                                        }
                                    </>
                                :
                                    null
                            }
                        </div>
                        
                        : 
                            is_template_enabled && show_template ?
                                <div className='col-3'>
                                    <I18n>
                                        {({ i18n }) =>
                                            <>
                                                {is_template_enabled ?
                                                    <>
                                                        {customer.enable_checklist ?
                                                            customer.behaviour === "pndsafety" ?
                                                                <>
                                                                    {isadmin ?
                                                                        <input type="image" id="addJob" src={AddButton} className="btn-green-add" alt={i18n._(t`Add Job`)}  onClick={()=>{this.changeJobView({action:'add_scan_checklist_template', showJobForm:true})}}/>
                                                                    :
                                                                        null
                                                                    }
                                                                </>
                                                            :
                                                                <input type="image" id="addJob" src={AddButton} className="btn-green-add" alt={i18n._(t`Add Job`)}  onClick={()=>{this.showHideJobOptions()}}/>
                                                        :
                                                            <input type="image" id="addJob" src={AddButton} className="btn-green-add" alt={i18n._(t`Add Job`)}  onClick={()=>{this.changeJobView({action:'add_evidence_template', showJobForm:true})}}/>
                                                        }
                                                        { isSmallFormFactor === false && document.getElementById("addJob")?
                                                            <UncontrolledTooltip placement="right" target="addJob">
                                                                <Trans>Click to add a new template</Trans>
                                                            </UncontrolledTooltip>
                                                            :                                           
                                                            ''
                                                        }
                                                        {/* TZ-870 */}
                                                        {
                                                            customer.enable_inspection || customer.enable_incident?
                                                                <>
                                                                    <a href="#" target="_blank" rel="noreferrer" id="power-bi-3" className="white" onClick={(e) => this.getRandomPass(e)} style={{position:"relative", bottom:"8px"}}>
                                                                        <i class="fas fa-chart-bar fa-2x"></i>
                                                                    </a>
                                                                    {isSmallFormFactor === false?
                                                                        <UncontrolledTooltip placement="down" target="power-bi-3">
                                                                            Dashboard
                                                                        </UncontrolledTooltip>
                                                                        :                                           
                                                                        null
                                                                    }
                                                                </>
                                                            :
                                                                null
                                                        }
                                                        <div ref={this.wrapperRef} className="dropdown-content pointer" style={{display:jobOptionDropdownOpen?"block":"none"}} onMouseLeave={() => this.setState({jobOptionDropdownOpen: false})}>
                                                            <a onClick={()=>{this.changeJobView({action:'add_evidence_template', showJobForm:true})}}>
                                                                <Trans>Add Template</Trans> - <Trans>Evidence</Trans>
                                                            </a>
                                                            <a onClick={()=>{this.changeJobView({action:'add_checklist_template', showJobForm:true})}}>
                                                                <Trans>Add Template</Trans> - <Trans>Checklist</Trans>
                                                            </a>
                                                        </div>
                                                    </>
                                                    :
                                                    null
                                                }
                                            </>
                                        }
                                    </I18n>
                                </div>
                            :
                            <div className='col-3'>
                                {   //TZ-580 TZ-39
                                    customer.behaviour === "honeywell" || customer.enable_incident || customer.enable_inspection?
                                        <>
                                            {/* <a href="https://app.powerbi.com/groups/me/reports/f1dd8bc3-e2ca-4aaf-98c8-f1a4801139ef/ReportSection57a06738459b0f332624?experience=power-bi" target="_blank" rel="noreferrer" id="power-bi-1">
                                                <input type="image" src="/Microsoft-Power-BI-Logo.png" alt="power bi"/>
                                            </a>
                                            { isSmallFormFactor === false?
                                                <UncontrolledTooltip placement="down" target="power-bi-1">
                                                    Dashboard 1
                                                </UncontrolledTooltip>
                                                :                                           
                                                null
                                            }
                                            <a href="https://app.powerbi.com/groups/me/reports/e8ca64c9-0dc1-4e94-a9e8-0f2efd9a350f/ReportSection?experience=power-bi" target="_blank" rel="noreferrer" id="power-bi-2">
                                                <input type="image" src="/Microsoft-Power-BI-Logo.png" alt="power bi"/>
                                            </a>
                                            { isSmallFormFactor === false?
                                                <UncontrolledTooltip placement="down" target="power-bi-2">
                                                    Dashboard 2
                                                </UncontrolledTooltip>
                                                :                                           
                                                null
                                            } */}
                                            <a href="#" target="_blank" rel="noreferrer" id="power-bi-3" className="white" onClick={(e) => this.getRandomPass(e)}>
                                                <i class="fas fa-chart-bar fa-2x"></i>
                                            </a>
                                            { isSmallFormFactor === false?
                                                <UncontrolledTooltip placement="down" target="power-bi-3">
                                                    Dashboard
                                                </UncontrolledTooltip>
                                                :                                           
                                                null
                                            }
                                        </>
                                    :
                                        null
                                }
                            </div>//TP-4098
                    }
                    <Row className='col-9 no-gutters'>
                        <Row className="no-gutters col-4">
                            {is_template_enabled ?
                                <>
                                    <div className='col-9'></div>
                                    <div className="pt-2 col-3 d-flex justify-content-center align-items-center">
                                        <h4 className="tm-semi-bold">
                                            {
                                                show_template ? <Trans>TEMPLATES</Trans> : 
                                                    customer.behaviour === "pndsafety" ? <Trans>Work Orders</Trans> : <Trans>JOBS</Trans>
                                            }
                                        </h4>
                                    </div>{/*TP-4661,5606*/}
                                </>
                                : null
                            }
                        </Row>
                        <Row id="job-wrapper" className="no-gutters col-8">
                            <Col className="text-right">
                                {
                                    !show_template?
                                            <ShowAllJobs {...this.state.showJobsCheckbox} toggleShowAllJobs={this.showAllJobs} showTemplateCheckbox={this.state.showTemplateCheckbox} 
                                                is_template_enabled={is_template_enabled} customer = {customer}/>
                                        :
                                            null
                                }
                            </Col>
                            {is_template_enabled ? 
                                <div className="col-3">
                                        <button type="button" className="btn-green-medium float-right" onClick={(e) => this.showAllTemplate(e)}>
                                            {show_template? customer.behaviour === "pndsafety" ? <><Trans>Show</Trans> <Trans>Work Order</Trans></> : <Trans>Show Jobs</Trans> : <Trans>Show Templates</Trans>}
                                        </button>
                                </div>
                                : 
                                null    
                            }
                            <div className="search-input-wrapper relative col-3">
                                <i className="fas fa-search" aria-hidden="true"></i>
                            
                                <I18n>
                                    {({ i18n }) => <input
                                            placeholder={ (show_template ? i18n._(t`Search for Templates`) : customer.behaviour === "pndsafety"? i18n._(t`Search for Work Order`) : i18n._(t`Search for Jobs`)) + '...' }
                                            type="text" 
                                            id="jobSearchBox" 
                                            onKeyUp={() => this.searchJobs()} 
                                        />
                                    }
                                </I18n>
                            </div>
                        </Row>
                    </Row>
                    
                </div>
                <div className="table-responsive">
                    <table id="jobsTable">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th id="name" onClick={() => this.sortJobs('name')}>
                                    <Trans>Name</Trans>
                                    { this.state.sortColumn.name !== undefined? <SortIcons direction={this.state.sortColumn.name} /> : ''} 
                                </th>
                                {
                                    customer.behaviour === "pndsafety" && !show_template?
                                        <th onClick={() => this.sortJobs('expert_name')}><Trans>Supervisor</Trans>
                                            { this.state.sortColumn.expert_name !== undefined? <SortIcons direction={this.state.sortColumn.expert_name} /> : ''} 
                                        </th>
                                    :
                                        null
                                }
                                <th onClick={() => this.sortJobs('assignee_name')}>
                                    <Trans>Assignee</Trans>
                                    { this.state.sortColumn.assignee_name !== undefined? <SortIcons direction={this.state.sortColumn.assignee_name} /> : ''} 
                                </th>
                                {
                                    customer.behaviour === "pndsafety" && !show_template?
                                        <th onClick={() => this.sortJobs('location_name')}>Location
                                            { this.state.sortColumn.location_name !== undefined? <SortIcons direction={this.state.sortColumn.location_name} /> : ''} 
                                        </th>
                                    :
                                        null
                                }
                                {
                                    (customer.enable_asset_class !== "disabled" && isadmin || (isexpert && ((enable_asset && customer.enable_asset_class === "enabled_on_demand") || customer.enable_asset_class === "enabled_for_all" ))) ?
                                        <th>
                                            <Trans>Asset</Trans>
                                        </th>
                                    :   null
                                }
                                <th onClick={() => this.sortJobs('status')}>
                                    <Trans>Status</Trans>
                                    { this.state.sortColumn.status !== undefined? <SortIcons direction={this.state.sortColumn.status} /> : ''} 
                                </th>
                                <th onClick={() => this.sortJobs('job_type')}>
                                    <Trans>Type</Trans>
                                    { this.state.sortColumn.job_type !== undefined? <SortIcons direction={this.state.sortColumn.job_type} /> : ''} 
                                </th>
                                <th style={{width:'15%'}}><Trans>Actions</Trans></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (jobsList.length > 0) ? 
                                    jobsList.map((row, index) => { 
                                        row.status = changeJobs == row.id ? "finished" : row.status;                                       
                                        return (
                                            <tr id={'row-'+index} key={index}>
                                                <td>{((currentPage-1)*perPage)+(index + 1)}</td>
                                                <>  {/**TP-4834 */}
                                                    {isSmallFormFactor === false ?
                                                        <UncontrolledTooltip placement="bottom" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'name-' + index}>
                                                            <> 
                                                                <div className="text-left">{(row.job_type === 'work_instruction_template' || row.job_type === 'checklist_template') ? <Trans>Template</Trans>:<Trans>Job</Trans>} <Trans>Name</Trans>: {row.name}</div>
                                                                <div className="text-left">{(row.job_type === 'work_instruction_template' || row.job_type === 'checklist_template') ? <Trans>Template</Trans>:<Trans>Job</Trans>} <Trans>Description</Trans>: {row.description && row.description.length> 100 ? row.description.substring(0,100) + "..." : row.description}</div>
                                                                {(row.job_type === 'work_instruction_template' || row.job_type === 'checklist_template') ? null : <div className="text-left"><Trans>Start Date</Trans>: {moment(row.start_date).format("DD MMMM YYYY, h:mm a")}</div>}
                                                                {(row.job_type === 'work_instruction_template' || row.job_type === 'checklist_template') ? null : <div className="text-left"><Trans>End Date</Trans>: {moment(row.end_date).format("DD MMMM YYYY, h:mm a")}</div>}
                                                            </>
                                                        </UncontrolledTooltip>
                                                        :
                                                        ''
                                                    }
                                                    <td id={'name-' + index}>{row.name.length> 30 ? row.name.substring(0,30) + "..." : row.name}</td> 
                                                </>
                                                <>
                                                    {
                                                        customer.behaviour === "pndsafety" && !show_template ?
                                                            <td>{(row.expert_first_name && row.expert_last_name) ? getExpertName( row ) : 'Unassigned'}</td> 
                                                        :
                                                            null
                                                    }
                                                </>
                                                <td>{(row.first_name && row.last_name) ? getAssigneeName( row ) : 'Unassigned'}</td>
                                                <>
                                                    {
                                                        customer.behaviour === "pndsafety" && !show_template ?
                                                            <>
                                                                {isSmallFormFactor === false && row.location_name?
                                                                    <UncontrolledTooltip placement="bottom" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'location-' + index}>
                                                                        <> 
                                                                            {row.location_name}
                                                                        </>
                                                                    </UncontrolledTooltip>
                                                                    :
                                                                    null
                                                                }
                                                                <td id={'location-' + index}>{row.location_name.length> 30 ? row.location_name.substring(0,30) + "..." : row.location_name}</td> 
                                                            </>
                                                        :
                                                            null
                                                    }
                                                </>
                                                { (customer.enable_asset_class !== "disabled" && isadmin || (isexpert && ((enable_asset && customer.enable_asset_class === "enabled_on_demand") || customer.enable_asset_class === "enabled_for_all" ))) ? 
                                                    row.asset_name ?
                                                        <>
                                                            {isSmallFormFactor === false ?
                                                                <UncontrolledTooltip placement="bottom" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'asset-name-' + index}>
                                                                    { row.asset_name }
                                                                </UncontrolledTooltip>
                                                                :
                                                                ''
                                                            }
                                                            <td id={'asset-name-' + index}>{row.asset_name && row.asset_name.length> 30 ? row.asset_name.substring(0,30) + "..." : row.asset_name}</td> 
                                                        </>
                                                        :
                                                        <td></td>
                                                    :
                                                    null
                                                }
                                                <td><Trans id={row.status}></Trans></td>
                                                {
                                                    (row.job_type === 'work_instruction_template' || row.job_type === 'checklist_template' || row.job_type === 'scan_checklist_template') ?
                                                        row.job_type === 'work_instruction_template' ? <td><Trans id="Evidence"></Trans></td> : 
                                                            (['scan_checklist_template'].includes(row.job_type)) ? <td><Trans id="Scan_checklist"></Trans></td> :
                                                                <td><Trans id="Checklist"></Trans></td>
                                                    :
                                                        <td><Trans id={row.job_type.capitalize()}></Trans></td>
                                                }
                                                <td className="actions">
                                                    <I18n>
                                                        {({ i18n }) =>
                                                            customer.behaviour === "pndsafety" && row.job_type === 'scan_checklist_template'?
                                                            <>
                                                                {isadmin ?
                                                                    <>
                                                                        {isSmallFormFactor === false ?
                                                                            <span 
                                                                                data-rh={i18n._(t`Edit Scan Checklist`)}
                                                                                className="icon-wrapper pointer" 
                                                                                onClick={() => this.changeJobViewForEdit(row)}>
                                                                                <i className="fas fa-edit"></i>
                                                                            </span>
                                                                            :
                                                                            <span 
                                                                                className="icon-wrapper pointer" 
                                                                                onClick={() => this.changeJobViewForEdit(row)}>
                                                                                <i className="fas fa-edit"></i>
                                                                            </span>
                                                                        }
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                                {//TP-5606
                                                                    isSmallFormFactor === false ?
                                                                    <span 
                                                                        data-rh={i18n._(t`Create Scan Checklist`)} className="icon-wrapper pointer" 
                                                                        onClick={(e) => this.changeJobView({action: 'clone_scan_checklist_job', showJobForm: true, selectedJob: row})}>
                                                                        <i className="fas fa-file-medical"></i>
                                                                    </span>  
                                                                    :
                                                                    <span 
                                                                        className="icon-wrapper pointer" onClick={(e) => this.changeJobView({action: 'clone_scan_checklist_job', showJobForm: true, selectedJob: row})}>
                                                                        <i className="fas fa-file-medical"></i>
                                                                    </span> 
                                                                }
                                                                {isadmin ?
                                                                    <>
                                                                        { isSmallFormFactor === false ?
                                                                            <span 
                                                                                data-rh={i18n._(t`Delete Scan Checklist`)} 
                                                                                className="icon-wrapper pointer" 
                                                                                onClick={() => this.changeJobView({action: 'delete', selectedJob: row})}>
                                                                                <i className="far fa-trash-alt"></i>
                                                                            </span>
                                                                            :
                                                                            <span 
                                                                                className="icon-wrapper pointer" 
                                                                                onClick={() => this.changeJobView({action: 'delete', selectedJob: row})}>
                                                                                <i className="far fa-trash-alt"></i>
                                                                            </span>
                                                                        }
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                            </>

                                                            :

                                                            <>
                                                                { row.status === 'created' && ((row.first_name && row.last_name) || row.asset_id) && row.job_type !== 'work_instruction_template' && row.job_type !== 'checklist_template'?
                                                                    isSmallFormFactor === false ?
                                                                        <span 
                                                                            data-rh={i18n._({ id: `Assign To Technician`, values: {role: this.props.customerRoles.technician}})} 
                                                                            className="icon-wrapper pointer" 
                                                                            onClick={(e) => this.assignToTechnician({e, selectedJob: row})}>
                                                                            <i className="fas fa-paper-plane"></i>
                                                                        </span>
                                                                        :
                                                                        <span 
                                                                            className="icon-wrapper pointer" 
                                                                            onClick={(e) => this.assignToTechnician({e, selectedJob: row})}>
                                                                            <i className="fas fa-paper-plane"></i>
                                                                        </span>
                                                                    
                                                                :
                                                                    <>
                                                                        {
                                                                            (customer.behaviour === "honeywell" || customer.enable_incident) && row.incidents && row.incidents.length > 0 ?
                                                                                <span onClick={() => this.showIncidents(row)} className="icon-wrapper pointer" data-rh="Incidents">
                                                                                    <i className="fas fa-exclamation-triangle"></i>
                                                                                </span>
                                                                            :
                                                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                                        }
                                                                    </>
                                                                }                                                                    
                                                                {   (row.status !== 'draft' && customer.behaviour === "pndsafety" && row.job_type === 'scan_checklist')?//TP-5606
                                                                        this.renderVideoIcon({row, index, jobInProgress})
                                                                    :
                                                                        null
                                                                }
                                                                {( isexpert || ( isadmin && (row.job_type === 'work_instruction_template' || row.job_type === 'checklist_template')) ) ? 
                                                                    isSmallFormFactor === false ?
                                                                        <span 
                                                                            data-rh={row.job_type === 'work_instruction_template' || row.job_type === 'checklist_template' ? i18n._(t`Edit Template`) :
                                                                                row.job_type === 'scan_checklist' ? i18n._(t`Edit Scan Checklist`) : i18n._(t`Edit Job`)}
                                                                            className="icon-wrapper pointer" 
                                                                            onClick={() => this.changeJobViewForEdit(row)}>
                                                                            <i className="fas fa-edit"></i>
                                                                        </span>
                                                                        :
                                                                        <span 
                                                                            className="icon-wrapper pointer" 
                                                                            onClick={() => this.changeJobViewForEdit(row)}>
                                                                            <i className="fas fa-edit"></i>
                                                                        </span>
                                                                :
                                                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                                }                                                                    
                                                                { row.status !== 'draft' && customer.behaviour !== "pndsafety"?//TP-5606
                                                                    isSmallFormFactor === false ?
                                                                        <span 
                                                                            data-rh={row.job_type === 'work_instruction_template' || row.job_type === 'checklist_template'? i18n._(t`Clone This Template`) : i18n._(t`Clone This Job`)} 
                                                                            className="icon-wrapper pointer" onClick={() => this.changeJobViewForClone(row)}>
                                                                            <i className="far fa-clone"></i>
                                                                        </span>  
                                                                        :
                                                                        <span 
                                                                            className="icon-wrapper pointer" onClick={() => this.changeJobViewForClone(row)}>
                                                                            <i className="far fa-clone"></i>
                                                                        </span> 
                                                                    :
                                                                    customer.behaviour === "pndsafety" ? null : <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                                }
                                                                {/* NS2-517 TP-2357*/}
                                                                {
                                                                    row.status !== 'draft' ?
                                                                        row.job_type !== 'work_instruction_template' && row.job_type !== 'checklist_template'?
                                                                            isSmallFormFactor === false ?
                                                                            <>
                                                                                { row.is_downloaded === true ?
                                                                                        <span data-rh={i18n._(t`Download Report`)} className="icon-wrapper pointer" 
                                                                                            onClick={() => this.onClickDownloadReport(((currentPage - 1) * perPage) + index)}>
                                                                                            <i id={'pdf_' + (((currentPage - 1) * perPage) + index)} className="far fa-file-pdf fa-file-pdf-o-read"></i>
                                                                                        </span>
                                                                                    :
                                                                                        <span data-rh={i18n._(t`Download Report`)} className="icon-wrapper pointer" onClick={() => this.onClickDownloadReport(((currentPage - 1) * perPage) + index)}>
                                                                                            <i id={'pdf_' + (((currentPage - 1) * perPage) + index)} className="far fa-file-pdf"></i>
                                                                                        </span>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                { row.is_downloaded === true ?
                                                                                        <span className="icon-wrapper pointer" 
                                                                                            onClick={() => this.onClickDownloadReport(((currentPage - 1) * perPage) + index)}>
                                                                                            <i id={'pdf_' + (((currentPage - 1) * perPage) + index)} className="far fa-file-pdf fa-file-pdf-o-read"></i>
                                                                                        </span>
                                                                                    :
                                                                                        <span className="icon-wrapper pointer" onClick={() => this.onClickDownloadReport(((currentPage - 1) * perPage) + index)}>
                                                                                            <i id={'pdf_' + (((currentPage - 1) * perPage) + index)} className="far fa-file-pdf"></i>
                                                                                        </span>
                                                                                }
                                                                            </>
                                                                        :
                                                                            isSmallFormFactor === false ?
                                                                                <span 
                                                                                    data-rh={row.job_type === 'checklist_template' ? i18n._(t`Create Checklist`) : i18n._(t`Create Work Instruction`)} 
                                                                                    className="icon-wrapper pointer" 
                                                                                    onClick={(e) => row.job_type === 'checklist_template'? this.changeJobView({action: 'clone_checklist_job', showJobForm: true, selectedJob: row}) :
                                                                                        this.changeJobView({action: 'clone_evidence_job', showJobForm: true, selectedJob: row})
                                                                                    }>
                                                                                    <i className="fas fa-file-medical"></i>
                                                                                </span>  
                                                                                :
                                                                                <span 
                                                                                    className="icon-wrapper pointer" 
                                                                                    onClick={(e) => row.job_type === 'checklist_template' ? this.changeJobView({action: 'clone_checklist_job', showJobForm: true, selectedJob: row}) :
                                                                                        this.changeJobView({action: 'clone_evidence_job', showJobForm: true, selectedJob: row})
                                                                                    }>
                                                                                    <i className="fas fa-file-medical"></i>
                                                                                </span> 
                                                                    :   <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                                }
                                                                {/* row.is_downloaded === true ?
                                                                    <span 
                                                                        data-rh={i18n._(t`Download Report`)} 
                                                                        className="icon-wrapper pointer" 
                                                                        onClick={() => this.onClickZipFile(((currentPage - 1) * perPage) + index)}>
                                                                        
                                                                            <i id={'pdf_' + (((currentPage - 1) * perPage) + index)} className="fas fa-cloud-download-alt fa-file-pdf-o-read"></i>
                                                                    </span>
                                                                    :
                                                                    <span data-rh={i18n._(t`Download Report`)} className="icon-wrapper pointer" onClick={() => this.onClickZipFile(((currentPage - 1) * perPage) + index)}>
                                                                        <i id={'pdf_' + (((currentPage - 1) * perPage) + index)} className="fas fa-cloud-download-alt"></i>
                                                                    </span>
                                                                 */}
                                                                { /**TZ-1454 */ getURL("staging") === false ?
                                                                    isSmallFormFactor === false ?
                                                                        <span 
                                                                            data-rh={row.job_type === 'work_instruction_template' || row.job_type === 'checklist_template' ? i18n._(t`Delete Template`) :
                                                                                row.job_type === 'scan_checklist' ? i18n._(t`Delete Scan Checklist`) : i18n._(t`Delete Job`)} 
                                                                            className="icon-wrapper pointer" 
                                                                            onClick={() => this.changeJobView({action: 'delete', selectedJob: row})}>
                                                                            <i className="far fa-trash-alt"></i>
                                                                        </span>
                                                                        :
                                                                        <span 
                                                                            className="icon-wrapper pointer" 
                                                                            onClick={() => this.changeJobView({action: 'delete', selectedJob: row})}>
                                                                            <i className="far fa-trash-alt"></i>
                                                                        </span>
                                                                    :
                                                                    null
                                                                }
                                                            </>
                                                        }
                                                    </I18n>
                                                </td>
                                            </tr>
                                        );
                                    })
                                :(
                                    <tr>
                                        {   
                                            customer.behaviour === "pndsafety" && !show_template?
                                                <td colSpan={9} style={{ textAlign: 'center' }}><Trans id={'No data found'}/></td>
                                            :
                                                <td colSpan={(customer.enable_asset_class !== "disabled" && isadmin || (isexpert && ((enable_asset && customer.enable_asset_class === "enabled_on_demand") || customer.enable_asset_class === "enabled_for_all" ))) ? 8 : 7 } style={{ textAlign: 'center' }}><Trans id={'No data found'}/></td>

                                        }
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <div className="pagination-wrapper d-flex justify-content-end">
                    <I18n>
                        {({ i18n }) => 
                            <ReactPaginate
                                previousLabel={i18n._(t`previous`)}
                                nextLabel={i18n._(t`next`)}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={10}
                                forcePage={this.state.pagination.currentPage -1}
                                onPageChange={this.handlePaginationClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-item'}
                                subContainerClassName={'pages pagination'}
                                pageLinkClassName={'page-link'}
                                disabledClassName={'disabled'}
                                activeClassName={'active'}
                                previousClassName={'page-item'}
                                nextClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextLinkClassName={'page-link'}
                                breakLinkClassName={'page-link'}
                            />
                        }
                    </I18n>
                </div>
                {this.showDataLoader()}
            </>);
    }

    renderVideoIcon = ({ row, index, jobInProgress }) => {
        const { isexpert, isadmin, isSmallFormFactor, noInternet, isConnected, onlineUsers, isMonthlyMinutesConsumed, userid /**TP-3303*/ } = this.props;
        const {micAvailable} = this.state;
        const isOnline = onlineUsers.includes(row.email)
        const job = jobInProgress.find(j => j.jobId === row.id)
        const isViewable =  job ? job.work_in_progress : (row.work_in_progress && row.assigned_by && userid === row.assigned_by)
        if (isOnline && isViewable && micAvailable && isexpert && !noInternet && isConnected && !isMonthlyMinutesConsumed) {
            return (
                <I18n>
                    {({ i18n }) =>
                        <>
                            { isSmallFormFactor === false ?
                                <span className="icon-wrapper pointer" data-rh={i18n._(t`Start the session`)} onClick={() => this.onClickVideoIcon({row, action: "callUser"})}>
                                    <i className="fas fa-video "></i>
                                </span>
                                :
                                <span className="icon-wrapper pointer" onClick={() => this.onClickVideoIcon({row, action: "callUser"})}>
                                    <i className="fas fa-video "></i>
                                </span>
                            }
                        </>
                    }
                </I18n>
            );
        }else if (isOnline && isViewable /*TP-1165*/ && !this.state.webcamAvailable && !(isadmin && !isexpert)) {
            return (
                <I18n>
                    {({ i18n }) =>
                        <span className="icon-wrapper pointer" >
                            { isSmallFormFactor === false ?
                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'tdvideo' + index}>
                                    <Trans>MicrophoneNotFoundError</Trans>
                                </UncontrolledTooltip>                            
                                :
                                null
                            }
                            <i className="fas fa-video fa-video-disabled " id={'tdvideo' + index}></i>
                        </span>
                    }
                </I18n>
            );
        }else if (isViewable /*TP-1165*/ && this.props.noInternet || !this.props.isConnected){
            return (
                <I18n>
                    {({ i18n }) =>
                        <>
                            { isSmallFormFactor === false ?
                                <span className="icon-wrapper pointer" data-rh={i18n._(t`No user is online`)}>
                                    <i className="fas fa-video fa-video-disabled "></i>
                                </span>
                                :
                                <span className="icon-wrapper pointer" >
                                    <i className="fas fa-video fa-video-disabled "></i>
                                </span>
                            }
                        </>
                    }
                </I18n>
            );
        }else if (isViewable /*TP-1165*/ && this.props.isMonthlyMinutesConsumed){
            return (
                <I18n>
                    {({ i18n }) =>
                        <span className="icon-wrapper pointer">
                            { isSmallFormFactor === false ?
                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'tdvideo' + index}>
                                    {
                                        <Trans>Monthly minutes over</Trans>                                     
                                    }
                                </UncontrolledTooltip>
                                :
                                ''
                            }
                            <i className="fas fa-video fa-video-disabled " id={'tdvideo' + index}></i>
                        </span>
                    }
                </I18n>
            );
        }else{
            return (
                <I18n>
                    {({ i18n }) =>
                        <>
                            { isSmallFormFactor === false ?
                                <span className="icon-wrapper pointer" data-rh={i18n._(t`Work order not in progress`)}>
                                    <i className="fas fa-video fa-video-disabled "></i>
                                </span>
                                :
                                <span className="icon-wrapper pointer" >
                                    <i className="fas fa-video fa-video-disabled "></i>
                                </span>
                            }
                        </>
                    }
                </I18n>
            );
        }
    }

    callDialer = () => {
        //TP-4228 -- Close out the dialog box automatically after 30 seconds, when response has come
        const {userCallResponse, selectedJob} = this.state;
        if (userCallResponse) {
            this.dialogTimer = setTimeout(() => {
                this.cancelWOCall({ action : 'view', msg : ''});
                clearTimeout(this.dialogTimer);
            }, 30000);
        }
        return <Modal size="sm" isOpen={true} className="call-notification-toast">
            <ModalHeader className="text-center">
                { userCallResponse ? <Trans>Call failed</Trans> : <Trans id="Calling {name}" values={{"name": `${selectedJob.first_name} ${selectedJob.last_name}`}}></Trans> }
            </ModalHeader>
            <ModalBody className="d-flex justify-content-start align-items-center">
            {userCallResponse ? '' : <Spinner className="align-self-center mr-2" type="grow" size="sm" />}
            {
                userCallResponse ?
                <Trans id={userCallResponse} values={{recipient: `${selectedJob.first_name} ${selectedJob.last_name}`}}></Trans>:
                <>
                    <Trans id="Calling {name}" values={{'name': `${selectedJob.first_name} ${selectedJob.last_name}`}}></Trans>
                    {
                        !this.state.isIOS ?
                            <audio autoPlay="true" loop="true" src="/incoming_call.mp3"/>
                        : ''
                    }
                </>
            }
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end">
                    <BButton size="sm" onClick={this.cancelWOCall}><Trans>Cancel</Trans></BButton>
                </div>
            </ModalFooter>
        </Modal>
    }

    endCallDialer = () => {
        const {userCallResponse, selectedJob} = this.state;
        return  <Modal size="sm" isOpen={true} className="call-notification-toast">
                    <ModalHeader className="text-center">
                        { userCallResponse ? <Trans>Call failed</Trans> : '' }
                    </ModalHeader>
                    <ModalBody className="d-flex justify-content-start align-items-center">                    
                    {
                        userCallResponse ?
                            <Trans id={userCallResponse} values={{recipient: `${selectedJob.first_name} ${selectedJob.last_name}`}}></Trans>
                        :
                        ''
                    }
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-end">
                            <BButton size="sm" onClick={this.closeTimeoutDialog}><Trans>Close</Trans></BButton>
                        </div>
                    </ModalFooter>
                </Modal>
    }
    render(){
        let {action, selectedJob} = this.state;
        let {isadmin, isexpert, enable_asset, isSmallFormFactor, userid} = this.props;//TP-2721
        let colorText = '#485890';
        if(this.state.retCode !== 200) colorText = 'red';
        //TP-3220,TP-3222 & TP-3223	
        return(
            <>
                {
                    (action=== 'add_evidence_job' || action === 'edit_evidence_job' || action === 'add_evidence_template' || action === 'edit_evidence_template') && this.state.showJobForm? 
                        <AccountConsumer>
                            {({language}) =>

                                <JobForms
                                    language={language}
                                    authService={this.props.authService}
                                    changeJobView={this.changeJobView}
                                    accountId={this.props.accountId}
                                    fetchJobs={this.fetchJobs}
                                    selectedJob={this.state.selectedJob}
                                    action={this.state.action}
                                    email={this.props.email}
                                    updateJobsDetails={this.updateJobsDetails}
                                    customer={this.props.customer}
                                    enable_asset={enable_asset}//TP-2721
                                    isexpert={isexpert}//TP-2721
                                    isadmin={isadmin}//TP-2721
                                    isSmallFormFactor={isSmallFormFactor} //TP-2947
                                    userid={userid}//TP-3161
                                    user_enable_job_scheduler={this.props.user_enable_job_scheduler}//TP-4891
                                    customerRoles={this.props.customerRoles}
                                    name={this.props.name}//TP-6640
                            />
                        }
                        </AccountConsumer>
                    : 
                        (action === 'clone_evidence_job' || action === 'clone_evidence_template') ?
                            <AccountConsumer>
                                {({language}) =>

                                    <CloneJobForms
                                        language={language}
                                        authService={this.props.authService}
                                        changeJobView={this.changeJobView}
                                        accountId={this.props.accountId}
                                        fetchJobs={this.fetchJobs}
                                        selectedJob={this.state.selectedJob}
                                        action={this.state.action}
                                        updateJobsDetails={this.updateJobsDetails}
                                        customer={this.props.customer}
                                        enable_asset={enable_asset}//TP-2721
                                        isexpert={isexpert}//TP-2721
                                        isadmin={isadmin}//TP-2721
                                        isSmallFormFactor={this.props.isSmallFormFactor} //TP-2947
                                        user_enable_job_scheduler={this.props.user_enable_job_scheduler}//TP-4891
                                        customerRoles={this.props.customerRoles}
                                        name={this.props.name}//TP-6640
                                    />
                                }
                            </AccountConsumer>
                        : 
                            (action === 'add_checklist' || action === 'edit_checklist' || action === 'clone_checklist_job' || action === 'add_checklist_template' || action === 'edit_checklist_template'
                                || action === 'clone_checklist_template' ) && this.state.showJobForm? 
                                <AccountConsumer>
                                    {({language}) =>

                                        <CheckListForms
                                            language={language}
                                            authService={this.props.authService}
                                            changeJobView={this.changeJobView}
                                            accountId={this.props.accountId}
                                            fetchJobs={this.fetchJobs}
                                            selectedJob={this.state.selectedJob}
                                            action={this.state.action}
                                            email={this.props.email}
                                            updateJobsDetails={this.updateJobsDetails}
                                            customer={this.props.customer}
                                            enable_asset={enable_asset}//TP-2721
                                            isexpert={isexpert}//TP-2721
                                            isadmin={isadmin}//TP-2721
                                            isSmallFormFactor={isSmallFormFactor} //TP-2947
                                            user_enable_job_scheduler={this.props.user_enable_job_scheduler}//TP-4891
                                            customerRoles={this.props.customerRoles}
                                        />
                                    }
                                </AccountConsumer>
                                :
                                    (action === 'add_scan_checklist' || action === 'edit_scan_checklist' || action === 'clone_scan_checklist_job' || action === 'add_scan_checklist_template' || action === 'edit_scan_checklist_template'
                                    || action === 'clone_scan_checklist_template' ) && this.state.showJobForm? 
                                    <AccountConsumer>
                                        {({language}) =>
                                            <ScanCheckListForms
                                                language={language}
                                                authService={this.props.authService}
                                                changeJobView={this.changeJobView}
                                                accountId={this.props.accountId}
                                                fetchJobs={this.fetchJobs}
                                                selectedJob={this.state.selectedJob}
                                                action={this.state.action}
                                                email={this.props.email}
                                                updateJobsDetails={this.updateJobsDetails}
                                                customer={this.props.customer}
                                                enable_asset={enable_asset}//TP-2721
                                                isexpert={isexpert}//TP-2721
                                                isadmin={isadmin}//TP-2721
                                                isSmallFormFactor={isSmallFormFactor} //TP-2947
                                                user_enable_job_scheduler={this.props.user_enable_job_scheduler}//TP-4891
                                                customerRoles={this.props.customerRoles}
                                            />
                                        }
                                    </AccountConsumer>
                                    :
                                        action === "show_incidents"?
                                            //TZ-37
                                            <AccountConsumer>
                                                {({language}) =>
                                                    <IncidentTable
                                                        language={language}
                                                        changeJobView={this.changeJobView}
                                                        selectedJob={this.state.selectedJob}
                                                        collapseIncidents={this.collapseIncidents}
                                                        incidents={this.state.incidents}
                                                        isSmallFormFactor={isSmallFormFactor} //TP-2947
                                                        onChangeIncidentForm={this.onChangeIncidentForm}
                                                        onSubmitIncident={this.onSubmitIncident}
                                                        validator={this.validator}
                                                        bSubmitted={this.state.bSubmitted}
                                                        errStrReturned={this.state.errStrReturned}
                                                        onChangeIncidentDropdown={this.onChangeIncidentDropdown}
                                                        priority_options={this.state.priority_options}
                                                        severity_options={this.state.severity_options}
                                                        status_options={this.state.status_options}
                                                    />
                                                }
                                            </AccountConsumer>
                                            :
                                                this.jobList()
                }
                {
                    action === 'delete' ?
                    <I18n>

                        {({ i18n }) =>
                            <ConfirmModal 
                                primaryButton={i18n._(t`Delete`)}
                                secondaryButton={i18n._(t`Cancel`)}
                                headerText={['work_instruction_template', "checklist_template", "scan_checklist_template"].includes(selectedJob.job_type) ? i18n._(t`Delete Template`):
                                            selectedJob.job_type === "scan_checklist" ? i18n._(t`Delete Work Order`) : i18n._(t`Delete Job`)
                                        }
                                message ={['work_instruction_template', "checklist_template", "scan_checklist_template"].includes(selectedJob.job_type) ? "Are you sure you want to delete Template ?" : 
                                            selectedJob.job_type === "scan_checklist" ? "Are you sure you want to delete Work Order ?" : "Are you sure you want to delete Job ?"}
                                values={selectedJob.name}
                                onClickSencondary={(e) => this.closeDeleteJobDialog(e)}
                                onClickPrimary={(e) => selectedJob.status === 'draft' || ['work_instruction_template', "checklist_template", "scan_checklist_template"].includes(selectedJob.job_type) ? this.onDeleteJob(e) : 
                                    this.changeJobView({action: 'archive_delete', selectedJob})
                                }
                            />
                        }
                    </I18n>
                    : ''
                }

                {
                    action === 'archive_delete' ?
                    <I18n>

                        {({ i18n }) =>
                            <ConfirmModal 
                                primaryButton={"Delete after downloading"}
                                secondaryButton={"Delete without downloading"}
                                headerText={selectedJob.job_type === "scan_checklist" ? i18n._(t`Confirm Delete Work Order`) : i18n._(t`Confirm Delete Job`)}
                                message={selectedJob.job_type === "scan_checklist" ? "Download archive before deleting the Work Order" : "Download archive before deleting the job"}
                                values={this.state.selectedJob.name}
                                onClickSencondary={(e) => this.onDeleteJob(e)}
                                onClickPrimary={(e) => this.onClickZipFile(e)}
                                onClickClose={(e) => this.closeDeleteJobDialog(e)}
                            />
                        }
                    </I18n>
                    : ''
                }

                {
                    action === 'message' && (this.state.bSubmitted || this.state.bResponded) ?
                        <div className='modale opened'>
                            <div className='__modal-dialog'>
                                <form>
                                    <div className="__modal-header">
                                        <h4 style={{color: colorText}}><Trans id={this.state.errStrReturned}/></h4>
                                    </div>
                                    <div className="__modal-footer flex-center">
                                        <button className="btn-green" onClick={() =>this.changeJobView({action: '', showJobForm: false})}><Trans id='OK'/></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    : ''
                }

                {
                    action === 'upload_workflow_template'?
                        <WorkFlowTemplate 
                            closeUploadJobDialog={this.closeDeleteJobDialog}
                            accountId={this.props.accountId}
                            customer={this.props.customer}
                            fetchJobs= {this.fetchJobs}
                            customerRoles={this.props.customerRoles}
                        />
                    :
                        ''
                }

                {
                    action === 'upload_checklist_template'?
                        <CheckListTemplate 
                            closeUploadJobDialog={this.closeDeleteJobDialog}
                            accountId={this.props.accountId}
                            customer={this.props.customer}
                            fetchJobs= {this.fetchJobs}
                            customerRoles={this.props.customerRoles}
                        />
                    :
                        ''
                }

                {
                    action === "callUser" ?
                        this.callDialer()
                    :
                        null
                }

                {
                    action === "timeoutCall" ?
                        this.endCallDialer()
                    :
                        null
                }

                <FlashMessage 
                    flashMsgText={this.state.flashMsgText} 
                    showMessage={this.state.showFlashMessage} 
                    flashLeft={this.state.flashLeft}
                    flashTop={this.state.flashTop}
                    updateShowMessage={this.updateShowMessage}
                    isTimerEnabled={true} /*TP-5986*/
                ></FlashMessage>
            </>                
        );
    }
}

export default Jobs;